import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/layouts/layout.js";
import { BlogImage } from "../../../components/BlogComponents";
import typeInference from "../../../images/dfinity/type-inference.png";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#overview"
        }}>{`Overview`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#type-inference-update"
        }}>{`Type Inference Update`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#change-to-index-file"
        }}>{`Change to index file`}</a>
        <ul parentName="li">
          <li parentName="ul"><a parentName="li" {...{
              "href": "#typescript-declaration-changes"
            }}>{`TypeScript Declaration Changes`}</a></li>
        </ul>
      </li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#changes-to-dfx-new"
        }}>{`Changes to dfx new`}</a>
        <ul parentName="li">
          <li parentName="ul"><a parentName="li" {...{
              "href": "#copying-the-declarations"
            }}>{`Copying the declarations`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#webpack-dev-server"
            }}>{`Webpack dev server`}</a></li>
        </ul>
      </li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#migrating-your-project"
        }}>{`Migrating your project`}</a>
        <ul parentName="li">
          <li parentName="ul"><a parentName="li" {...{
              "href": "#environment-variables"
            }}>{`Environment variables`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#minimal-update"
            }}>{`Minimal Update`}</a></li>
        </ul>
      </li>
    </ul>
    <h2 {...{
      "id": "overview"
    }}><a parentName="h2" {...{
        "href": "#overview"
      }}>{`Overview`}</a></h2>
    <p>{`The SDK team has an upcoming release candidate for `}<inlineCode parentName="p">{`dfx`}</inlineCode>{` that you can install today by running `}</p>
    <pre><code parentName="pre" {...{}}>{`DFX_VERSION=0.7.7 sh -ci "$(curl -fsSL https://sdk.dfinity.org/install.sh)"
`}</code></pre>
    <p><inlineCode parentName="p">{`dfx 0.7.7`}</inlineCode>{` will be our first promoted candidate in a while. There are some small bug fixes and updates to Motoko and Candid, but I want to explain some frontend changes that will be coming. I know that I'll be causing some trouble, and that I won't get it right the first time around, but I think these changes will help make developing for the IC simpler for most people, and it takes us in the direction of making more things "just work".`}</p>
    <p>{`The big ticket items are - `}</p>
    <ul>
      <li parentName="ul">{`automatic TypeScript support and tab completion for your actors`}</li>
      <li parentName="ul">{`Nicely configured Webpack dev server in `}<inlineCode parentName="li">{`dfx new`}</inlineCode></li>
      <li parentName="ul">{`Resolving the `}<inlineCode parentName="li">{`fetchRootKey`}</inlineCode>{` bug.`}</li>
    </ul>
    <p>{`This will be the most disruptive set of changes to frontend development, but I hope that this writeup helps make sense of the decisions I've made, and makes the upgrade mild.`}</p>
    <h2 {...{
      "id": "type-inference-update"
    }}><a parentName="h2" {...{
        "href": "#type-inference-update"
      }}>{`Type Inference Update`}</a></h2>
    <p>{`This is the main change I want to address. Starting with `}<inlineCode parentName="p">{`dfx`}</inlineCode>{` `}<inlineCode parentName="p">{`0.7.7`}</inlineCode>{`, `}<inlineCode parentName="p">{`dfx`}</inlineCode>{` will now provide you with generated declaration files that automatically provide you with correct typing based on your app's candid service. In your JavaScript or Typescript application, you will simply be able to run`}</p>
    <pre {...{
      "className": "language-js"
    }}><code parentName="pre" {...{
        "className": "language-js"
      }}><span parentName="code" {...{
          "className": "token keyword module"
        }}>{`import`}</span>{` `}<span parentName="code" {...{
          "className": "token imports"
        }}><span parentName="span" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{` hello `}<span parentName="span" {...{
            "className": "token punctuation"
          }}>{`}`}</span></span>{` `}<span parentName="code" {...{
          "className": "token keyword module"
        }}>{`from`}</span>{` `}<span parentName="code" {...{
          "className": "token string"
        }}>{`'../declarations/hello'`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`;`}</span>{`
`}</code></pre>
    <p>{`and your IDE (tested in VSCode and JetBrains) will know your full service, with tab completion and the ability to infer return types from calls to your API.`}</p>
    <BlogImage src={typeInference} mdxType="BlogImage">
    Example of IntelliSense for an asset canister's service methods
    </BlogImage>
    <p>{`These methods will allow frontend developers to interact nicely with the interface, and even know all the detail of what their return types will look like while working in the code. It's even more powerful in TypeScript applications!`}</p>
    <p>{`To make this possible, there are some changes to the codegen that we will create for you. `}</p>
    <h3 {...{
      "id": "change-to-index-file"
    }}><a parentName="h3" {...{
        "href": "#change-to-index-file"
      }}>{`Change to index file`}</a></h3>
    <p>{`Previously, under `}<inlineCode parentName="p">{`.dfx/local/canisters/<canister-name>`}</inlineCode>{`, we would output a `}<inlineCode parentName="p">{`<canister-name>.js`}</inlineCode>{` file. Going forward, to avoid duplicating the directory name, this file will be renamed to `}<inlineCode parentName="p">{`index.js`}</inlineCode>{`.`}</p>
    <p>{`Here's what that file will look like now, for a `}<inlineCode parentName="p">{`hello`}</inlineCode>{` project.`}</p>
    <pre {...{
      "className": "language-js"
    }}><code parentName="pre" {...{
        "className": "language-js"
      }}><span parentName="code" {...{
          "className": "token comment"
        }}>{`// src/declarations/hello/index.js`}</span>{`
`}<span parentName="code" {...{
          "className": "token keyword module"
        }}>{`import`}</span>{` `}<span parentName="code" {...{
          "className": "token imports"
        }}><span parentName="span" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{` `}<span parentName="span" {...{
            "className": "token maybe-class-name"
          }}>{`Actor`}</span><span parentName="span" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="span" {...{
            "className": "token maybe-class-name"
          }}>{`HttpAgent`}</span>{` `}<span parentName="span" {...{
            "className": "token punctuation"
          }}>{`}`}</span></span>{` `}<span parentName="code" {...{
          "className": "token keyword module"
        }}>{`from`}</span>{` `}<span parentName="code" {...{
          "className": "token string"
        }}>{`"@dfinity/agent"`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`;`}</span>{`

`}<span parentName="code" {...{
          "className": "token comment"
        }}>{`// Imports candid interface`}</span>{`
`}<span parentName="code" {...{
          "className": "token keyword module"
        }}>{`import`}</span>{` `}<span parentName="code" {...{
          "className": "token imports"
        }}><span parentName="span" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{` idlFactory `}<span parentName="span" {...{
            "className": "token punctuation"
          }}>{`}`}</span></span>{` `}<span parentName="code" {...{
          "className": "token keyword module"
        }}>{`from`}</span>{` `}<span parentName="code" {...{
          "className": "token string"
        }}>{`'./hello.did.js'`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`;`}</span>{`
`}<span parentName="code" {...{
          "className": "token comment"
        }}>{`// CANISTER_ID is replaced by webpack based on node environment`}</span>{`
`}<span parentName="code" {...{
          "className": "token keyword module"
        }}>{`export`}</span>{` `}<span parentName="code" {...{
          "className": "token keyword"
        }}>{`const`}</span>{` canisterId `}<span parentName="code" {...{
          "className": "token operator"
        }}>{`=`}</span>{` process`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`.`}</span><span parentName="code" {...{
          "className": "token property-access"
        }}>{`env`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`.`}</span><span parentName="code" {...{
          "className": "token constant"
        }}>{`HELLO_CANISTER_ID`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`;`}</span>{`

`}<span parentName="code" {...{
          "className": "token doc-comment comment"
        }}>{`/**
 * 
 * `}<span parentName="span" {...{
            "className": "token keyword"
          }}>{`@param`}</span>{` `}<span parentName="span" {...{
            "className": "token class-name"
          }}><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`{`}</span>{`string `}<span parentName="span" {...{
              "className": "token operator"
            }}>{`|`}</span>{` Principal`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`}`}</span></span>{` `}<span parentName="span" {...{
            "className": "token parameter"
          }}>{`canisterId`}</span>{` Canister ID of Agent
 * `}<span parentName="span" {...{
            "className": "token keyword"
          }}>{`@param`}</span>{` `}<span parentName="span" {...{
            "className": "token class-name"
          }}><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`{`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`{`}</span>{`agentOptions`}<span parentName="span" {...{
              "className": "token operator"
            }}>{`?`}</span><span parentName="span" {...{
              "className": "token operator"
            }}>{`:`}</span>{` `}<span parentName="span" {...{
              "className": "token keyword module"
            }}>{`import`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`(`}</span><span parentName="span" {...{
              "className": "token string"
            }}>{`"@dfinity/agent"`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`)`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`.`}</span>{`HttpAgentOptions`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`;`}</span>{` actorOptions`}<span parentName="span" {...{
              "className": "token operator"
            }}>{`?`}</span><span parentName="span" {...{
              "className": "token operator"
            }}>{`:`}</span>{` `}<span parentName="span" {...{
              "className": "token keyword module"
            }}>{`import`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`(`}</span><span parentName="span" {...{
              "className": "token string"
            }}>{`"@dfinity/agent"`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`)`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`.`}</span>{`ActorConfig`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`}`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`}`}</span></span>{` `}<span parentName="span" {...{
            "className": "token optional-parameter"
          }}><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`[`}</span><span parentName="span" {...{
              "className": "token parameter"
            }}>{`options`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`]`}</span></span>{`
 * `}<span parentName="span" {...{
            "className": "token keyword"
          }}>{`@return`}</span>{` `}<span parentName="span" {...{
            "className": "token class-name"
          }}><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`{`}</span><span parentName="span" {...{
              "className": "token keyword module"
            }}>{`import`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`(`}</span><span parentName="span" {...{
              "className": "token string"
            }}>{`"@dfinity/agent"`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`)`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`.`}</span>{`ActorSubclass`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`<`}</span><span parentName="span" {...{
              "className": "token keyword module"
            }}>{`import`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`(`}</span><span parentName="span" {...{
              "className": "token string"
            }}>{`"./hello.did.js"`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`)`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`.`}</span>{`_SERVICE`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`}`}</span></span>{`
 */`}</span>{`
 `}<span parentName="code" {...{
          "className": "token keyword module"
        }}>{`export`}</span>{` `}<span parentName="code" {...{
          "className": "token keyword"
        }}>{`const`}</span>{` `}<span parentName="code" {...{
          "className": "token function-variable function"
        }}>{`createActor`}</span>{` `}<span parentName="code" {...{
          "className": "token operator"
        }}>{`=`}</span>{` `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`(`}</span><span parentName="code" {...{
          "className": "token parameter"
        }}>{`canisterId`}<span parentName="span" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` options`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`)`}</span>{` `}<span parentName="code" {...{
          "className": "token arrow operator"
        }}>{`=>`}</span>{` `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`{`}</span>{`
  `}<span parentName="code" {...{
          "className": "token keyword"
        }}>{`const`}</span>{` agent `}<span parentName="code" {...{
          "className": "token operator"
        }}>{`=`}</span>{` `}<span parentName="code" {...{
          "className": "token keyword"
        }}>{`new`}</span>{` `}<span parentName="code" {...{
          "className": "token class-name"
        }}>{`HttpAgent`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`(`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`{`}</span>{` `}<span parentName="code" {...{
          "className": "token spread operator"
        }}>{`...`}</span>{`options`}<span parentName="code" {...{
          "className": "token operator"
        }}>{`?.`}</span>{`agentOptions `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`}`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`)`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`;`}</span>{`
  
  `}<span parentName="code" {...{
          "className": "token comment"
        }}>{`// Fetch root key for certificate validation during development`}</span>{`
  `}<span parentName="code" {...{
          "className": "token keyword control-flow"
        }}>{`if`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`(`}</span>{`process`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`.`}</span><span parentName="code" {...{
          "className": "token property-access"
        }}>{`env`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`.`}</span><span parentName="code" {...{
          "className": "token constant"
        }}>{`NODE_ENV`}</span>{` `}<span parentName="code" {...{
          "className": "token operator"
        }}>{`!==`}</span>{` `}<span parentName="code" {...{
          "className": "token string"
        }}>{`"production"`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`)`}</span>{` agent`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`.`}</span><span parentName="code" {...{
          "className": "token method function property-access"
        }}>{`fetchRootKey`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`(`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`)`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`;`}</span>{`

  `}<span parentName="code" {...{
          "className": "token comment"
        }}>{`// Creates an actor with using the candid interface and the HttpAgent`}</span>{`
  `}<span parentName="code" {...{
          "className": "token keyword control-flow"
        }}>{`return`}</span>{` `}<span parentName="code" {...{
          "className": "token maybe-class-name"
        }}>{`Actor`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`.`}</span><span parentName="code" {...{
          "className": "token method function property-access"
        }}>{`createActor`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`(`}</span>{`idlFactory`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`,`}</span>{` `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`{`}</span>{`
    agent`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`,`}</span>{`
    canisterId`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`,`}</span>{`
    `}<span parentName="code" {...{
          "className": "token spread operator"
        }}>{`...`}</span>{`options`}<span parentName="code" {...{
          "className": "token operator"
        }}>{`?.`}</span>{`actorOptions`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`,`}</span>{`
  `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`}`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`)`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`;`}</span>{`
`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`}`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`;`}</span>{`
  
`}<span parentName="code" {...{
          "className": "token doc-comment comment"
        }}>{`/**
 * A ready-to-use agent for the hello canister
 * `}<span parentName="span" {...{
            "className": "token keyword"
          }}>{`@type`}</span>{` `}<span parentName="span" {...{
            "className": "token class-name"
          }}><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`{`}</span><span parentName="span" {...{
              "className": "token keyword module"
            }}>{`import`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`(`}</span><span parentName="span" {...{
              "className": "token string"
            }}>{`"@dfinity/agent"`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`)`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`.`}</span>{`ActorSubclass`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`<`}</span><span parentName="span" {...{
              "className": "token keyword module"
            }}>{`import`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`(`}</span><span parentName="span" {...{
              "className": "token string"
            }}>{`"./hello.did.js"`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`)`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`.`}</span>{`_SERVICE`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`}`}</span></span>{`
 */`}</span>{`
 `}<span parentName="code" {...{
          "className": "token keyword module"
        }}>{`export`}</span>{` `}<span parentName="code" {...{
          "className": "token keyword"
        }}>{`const`}</span>{` hello `}<span parentName="code" {...{
          "className": "token operator"
        }}>{`=`}</span>{` `}<span parentName="code" {...{
          "className": "token function"
        }}>{`createActor`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`(`}</span>{`canisterId`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`)`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`;`}</span>{`
`}</code></pre>
    <p>{`Previously, we exported the IDL, a hardcoded `}<inlineCode parentName="p">{`canisterId`}</inlineCode>{`, and then had you assemble the actor yourself. Now, we are setting you up with a `}<inlineCode parentName="p">{`createActor`}</inlineCode>{` export and a `}<inlineCode parentName="p">{`hello`}</inlineCode>{` export. The `}<inlineCode parentName="p">{`createActor`}</inlineCode>{` function accepts a `}<inlineCode parentName="p">{`canisterId`}</inlineCode>{` and `}<inlineCode parentName="p">{`options`}</inlineCode>{`, and automatically sets you up with the `}<inlineCode parentName="p">{`idlFactory`}</inlineCode>{` from `}<inlineCode parentName="p">{`./hello.did.js`}</inlineCode>{`.`}</p>
    <p>{`If you have used recent versions of `}<inlineCode parentName="p">{`@dfinity/agent`}</inlineCode>{`, you probably also noticed that we have added logic to fix the common `}<inlineCode parentName="p">{`fetchRootKey`}</inlineCode>{` error that has been in place since Genesis. The `}<inlineCode parentName="p">{`createActor`}</inlineCode>{` function will call `}<inlineCode parentName="p">{`agent.fetchRootKey`}</inlineCode>{` for you during local development, but will not call it in production. This gives you the ease of not thinking about the root certificate, with the security benefits of your code only trusting a valid subnet that signs calls with the root key of the NNS, hardcoded into `}<inlineCode parentName="p">{`@dfinity/agent`}</inlineCode>{`.`}</p>
    <p>{`The return type of createActor is an `}<inlineCode parentName="p">{`ActorSubclass`}</inlineCode>{`, linked with the `}<inlineCode parentName="p">{`_SERVICE`}</inlineCode>{` interface from the adjacent `}<inlineCode parentName="p">{`.d.ts`}</inlineCode>{` file. This is accomplished using JSDoc comments. Finally, at the bottom, we export a `}<inlineCode parentName="p">{`hello`}</inlineCode>{` actor, which sets you up with an already created actor for situations where you don't need to configure a specific `}<inlineCode parentName="p">{`identity`}</inlineCode>{`, `}<inlineCode parentName="p">{`host`}</inlineCode>{`, or other options to the `}<inlineCode parentName="p">{`HttpAgent`}</inlineCode>{`.`}</p>
    <p>{`Speaking of the `}<inlineCode parentName="p">{`.d.ts`}</inlineCode>{` file..`}</p>
    <h3 {...{
      "id": "typescript-declaration-changes"
    }}><a parentName="h3" {...{
        "href": "#typescript-declaration-changes"
      }}>{`TypeScript Declaration Changes`}</a></h3>
    <ol>
      <li parentName="ol">{`The `}<inlineCode parentName="li">{`<canister-name>.d.ts`}</inlineCode>{` file has been renamed to `}<inlineCode parentName="li">{`<canister-name>.did.d.ts`}</inlineCode>{`.`}</li>
    </ol>
    <p>{`This change was suggested on `}<a parentName="p" {...{
        "href": "https://forum.dfinity.org/t/bug-report-dfx-auto-gen-canister-d-ts-wrong-naming/4468"
      }}>{`our forum`}</a>{`. The types are in reference to the IDL interface from `}<inlineCode parentName="p">{`.did.js`}</inlineCode>{`, so it's correct for the type declaration file to reflect its peer instead of the index.js`}</p>
    <p>{`Additionally, we have updated `}<inlineCode parentName="p">{`dfx`}</inlineCode>{` to the latest versions of Candid and Motoko. The main thing to mention here is that the output of `}<inlineCode parentName="p">{`<canister-name>.did.d.ts`}</inlineCode>{` may have changed slightly, and that the service is no longer a default export.`}</p>
    <h2 {...{
      "id": "changes-to-dfx-new"
    }}><a parentName="h2" {...{
        "href": "#changes-to-dfx-new"
      }}>{`Changes to dfx new`}</a></h2>
    <p>{`To accommodate these changes, we have made some changes to the `}<inlineCode parentName="p">{`dfx new`}</inlineCode>{` project. Most of the changes are to `}<inlineCode parentName="p">{`webpack.config.js`}</inlineCode>{` and to `}<inlineCode parentName="p">{`package.json`}</inlineCode>{`, but I'll go through them one-by-one.`}</p>
    <h3 {...{
      "id": "copying-the-declarations"
    }}><a parentName="h3" {...{
        "href": "#copying-the-declarations"
      }}>{`Copying the declarations`}</a></h3>
    <p>{`This is a pattern that I personally now recommend - committing your codegen files to your source code. It's how we operated for the Cycles Wallet, Internet Identity, and other internal projects on an ad-hoc basis, and it turned out to be the only reliable way to support type inferenece without fragile path references in `}<inlineCode parentName="p">{`tsconfig.json`}</inlineCode>{`.`}</p>
    <p>{`In the new `}<inlineCode parentName="p">{`package.json`}</inlineCode>{`, we provide you with a `}<inlineCode parentName="p">{`"copy:types"`}</inlineCode>{` script that looks like this: `}</p>
    <pre {...{
      "className": "language-json"
    }}><code parentName="pre" {...{
        "className": "language-json"
      }}><span parentName="code" {...{
          "className": "token property"
        }}>{`"copy:types"`}</span><span parentName="code" {...{
          "className": "token operator"
        }}>{`:`}</span>{` `}<span parentName="code" {...{
          "className": "token string"
        }}>{`"rsync -avr .dfx/$(echo \${DFX_NETWORK:-'**'})/canisters/** --exclude='assets/' --exclude='idl/' --exclude='*.wasm' --delete src/declarations"`}</span>{`
`}</code></pre>
    <p>{`This script copies the contents of your canisters in `}<inlineCode parentName="p">{`.dfx/local/canisters`}</inlineCode>{` and copies them to their own directories in `}<inlineCode parentName="p">{`src/declarations`}</inlineCode>{`, ignoring the `}<inlineCode parentName="p">{`wasm`}</inlineCode>{` module.`}</p>
    <p>{`From that point on, you'll be able to import directly from `}<inlineCode parentName="p">{`src/declarations/<canister-name>`}</inlineCode>{`, and your editor will be able to behave normally, without needing bundler aliases or unusual typescript configuration.`}</p>
    <p>{`The `}<inlineCode parentName="p">{`dfx new`}</inlineCode>{` project also has logic to sync the types whenever you run `}<inlineCode parentName="p">{`npm run build`}</inlineCode>{` or `}<inlineCode parentName="p">{`npm start`}</inlineCode>{`. This is the easiest workflow if you are doing fullstack work for now - build your file and the next time you start the frontend server, you'll get the new interfaces.`}</p>
    <blockquote>
      <p parentName="blockquote">{`Note: more details about syncing in the `}<a parentName="p" {...{
          "href": "#migrating-your-project"
        }}>{`migrating section`}</a></p>
    </blockquote>
    <h3 {...{
      "id": "webpack-dev-server"
    }}><a parentName="h3" {...{
        "href": "#webpack-dev-server"
      }}>{`Webpack dev server`}</a></h3>
    <p>{`At last, give you a pleasantly-configured dev server set up out of the box. `}<inlineCode parentName="p">{`npm start`}</inlineCode>{` will start the server directly at `}<inlineCode parentName="p">{`http://localhost:8080`}</inlineCode>{`, and you can simply open it, without needing to enter a `}<inlineCode parentName="p">{`canisterId`}</inlineCode>{` query parameter at `}<inlineCode parentName="p">{`localhost:8000`}</inlineCode>{`. `}</p>
    <p>{`Changes to your `}<inlineCode parentName="p">{`assets`}</inlineCode>{` directory or `}<inlineCode parentName="p">{`src`}</inlineCode>{` in the `}<inlineCode parentName="p">{`<canister-name>_assets`}</inlineCode>{` directory will kick off hot-reload updates to the browser. API calls to will be proxied to `}<inlineCode parentName="p">{`localhost:8000`}</inlineCode>{`, so you can interact with the local replica seamlessly. `}</p>
    <h2 {...{
      "id": "migrating-your-project"
    }}><a parentName="h2" {...{
        "href": "#migrating-your-project"
      }}>{`Migrating your project`}</a></h2>
    <p>{`If you have an existing project, you may need to make some adjustments when upgrading to 0.7.7+.`}</p>
    <p>{`As I've mentioned, we are moving toward a pattern of development where the generated files no longer live in `}<inlineCode parentName="p">{`.dfx`}</inlineCode>{` but instead will live as source code. `}</p>
    <h3 {...{
      "id": "environment-variables"
    }}><a parentName="h3" {...{
        "href": "#environment-variables"
      }}>{`Environment variables`}</a></h3>
    <p>{`With webpack, we are providing those environment variables by using an EnvironmentPlugin. At the top of `}<inlineCode parentName="p">{`webpack.config.js`}</inlineCode>{`, we read from the root `}<inlineCode parentName="p">{`canister_ids.json`}</inlineCode>{` and the one inside `}<inlineCode parentName="p">{`.dfx/local`}</inlineCode>{` to map the canister IDs into environment variables, and then replace the `}<inlineCode parentName="p">{`process.env`}</inlineCode>{` values in the code during development or at build time.`}</p>
    <pre {...{
      "className": "language-js"
    }}><code parentName="pre" {...{
        "className": "language-js"
      }}><span parentName="code" {...{
          "className": "token comment"
        }}>{`// webpack.config.js`}</span>{`
`}<span parentName="code" {...{
          "className": "token keyword"
        }}>{`let`}</span>{` localCanisters`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`,`}</span>{` prodCanisters`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`,`}</span>{` canisters`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`;`}</span>{`

`}<span parentName="code" {...{
          "className": "token keyword control-flow"
        }}>{`try`}</span>{` `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`{`}</span>{`
  localCanisters `}<span parentName="code" {...{
          "className": "token operator"
        }}>{`=`}</span>{` `}<span parentName="code" {...{
          "className": "token function"
        }}>{`require`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`(`}</span>{`path`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`.`}</span><span parentName="code" {...{
          "className": "token method function property-access"
        }}>{`resolve`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`(`}</span><span parentName="code" {...{
          "className": "token string"
        }}>{`".dfx"`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`,`}</span>{` `}<span parentName="code" {...{
          "className": "token string"
        }}>{`"local"`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`,`}</span>{` `}<span parentName="code" {...{
          "className": "token string"
        }}>{`"canister_ids.json"`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`)`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`)`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`;`}</span>{`
`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`}`}</span>{` `}<span parentName="code" {...{
          "className": "token keyword control-flow"
        }}>{`catch`}</span>{` `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`(`}</span>{`error`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`)`}</span>{` `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`{`}</span>{`
  `}<span parentName="code" {...{
          "className": "token console class-name"
        }}>{`console`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`.`}</span><span parentName="code" {...{
          "className": "token method function property-access"
        }}>{`log`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`(`}</span><span parentName="code" {...{
          "className": "token string"
        }}>{`"No local canister_ids.json found. Continuing production"`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`)`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`;`}</span>{`
`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`}`}</span>{`

`}<span parentName="code" {...{
          "className": "token keyword"
        }}>{`function`}</span>{` `}<span parentName="code" {...{
          "className": "token function"
        }}>{`initCanisterIds`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`(`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`)`}</span>{` `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`{`}</span>{`
  `}<span parentName="code" {...{
          "className": "token keyword control-flow"
        }}>{`try`}</span>{` `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`{`}</span>{`
    prodCanisters `}<span parentName="code" {...{
          "className": "token operator"
        }}>{`=`}</span>{` `}<span parentName="code" {...{
          "className": "token function"
        }}>{`require`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`(`}</span>{`path`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`.`}</span><span parentName="code" {...{
          "className": "token method function property-access"
        }}>{`resolve`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`(`}</span><span parentName="code" {...{
          "className": "token string"
        }}>{`"canister_ids.json"`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`)`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`)`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`;`}</span>{`
  `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`}`}</span>{` `}<span parentName="code" {...{
          "className": "token keyword control-flow"
        }}>{`catch`}</span>{` `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`(`}</span>{`error`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`)`}</span>{` `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`{`}</span>{`
    `}<span parentName="code" {...{
          "className": "token console class-name"
        }}>{`console`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`.`}</span><span parentName="code" {...{
          "className": "token method function property-access"
        }}>{`log`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`(`}</span><span parentName="code" {...{
          "className": "token string"
        }}>{`"No production canister_ids.json found. Continuing with local"`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`)`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`;`}</span>{`
  `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`}`}</span>{`

  `}<span parentName="code" {...{
          "className": "token keyword"
        }}>{`const`}</span>{` network `}<span parentName="code" {...{
          "className": "token operator"
        }}>{`=`}</span>{`
    process`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`.`}</span><span parentName="code" {...{
          "className": "token property-access"
        }}>{`env`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`.`}</span><span parentName="code" {...{
          "className": "token constant"
        }}>{`DFX_NETWORK`}</span>{` `}<span parentName="code" {...{
          "className": "token operator"
        }}>{`||`}</span>{`
    `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`(`}</span>{`process`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`.`}</span><span parentName="code" {...{
          "className": "token property-access"
        }}>{`env`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`.`}</span><span parentName="code" {...{
          "className": "token constant"
        }}>{`NODE_ENV`}</span>{` `}<span parentName="code" {...{
          "className": "token operator"
        }}>{`===`}</span>{` `}<span parentName="code" {...{
          "className": "token string"
        }}>{`"production"`}</span>{` `}<span parentName="code" {...{
          "className": "token operator"
        }}>{`?`}</span>{` `}<span parentName="code" {...{
          "className": "token string"
        }}>{`"ic"`}</span>{` `}<span parentName="code" {...{
          "className": "token operator"
        }}>{`:`}</span>{` `}<span parentName="code" {...{
          "className": "token string"
        }}>{`"local"`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`)`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`;`}</span>{`

  canisters `}<span parentName="code" {...{
          "className": "token operator"
        }}>{`=`}</span>{` network `}<span parentName="code" {...{
          "className": "token operator"
        }}>{`===`}</span>{` `}<span parentName="code" {...{
          "className": "token string"
        }}>{`"local"`}</span>{` `}<span parentName="code" {...{
          "className": "token operator"
        }}>{`?`}</span>{` localCanisters `}<span parentName="code" {...{
          "className": "token operator"
        }}>{`:`}</span>{` prodCanisters`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`;`}</span>{`

  `}<span parentName="code" {...{
          "className": "token keyword control-flow"
        }}>{`for`}</span>{` `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`(`}</span><span parentName="code" {...{
          "className": "token keyword"
        }}>{`const`}</span>{` canister `}<span parentName="code" {...{
          "className": "token keyword"
        }}>{`in`}</span>{` canisters`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`)`}</span>{` `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`{`}</span>{`
    process`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`.`}</span><span parentName="code" {...{
          "className": "token property-access"
        }}>{`env`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`[`}</span>{`canister`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`.`}</span><span parentName="code" {...{
          "className": "token method function property-access"
        }}>{`toUpperCase`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`(`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`)`}</span>{` `}<span parentName="code" {...{
          "className": "token operator"
        }}>{`+`}</span>{` `}<span parentName="code" {...{
          "className": "token string"
        }}>{`"_CANISTER_ID"`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`]`}</span>{` `}<span parentName="code" {...{
          "className": "token operator"
        }}>{`=`}</span>{`
      canisters`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`[`}</span>{`canister`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`]`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`[`}</span>{`network`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`]`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`;`}</span>{`
  `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`}`}</span>{`
`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`}`}</span>{`
`}<span parentName="code" {...{
          "className": "token function"
        }}>{`initCanisterIds`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`(`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`)`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`;`}</span>{`
`}</code></pre>
    <p>{`If it is difficult or not worthwhile for you to use the webpack config that we provide in the `}<inlineCode parentName="p">{`dfx new`}</inlineCode>{` project, here are some things to consider:`}</p>
    <ol>
      <li parentName="ol">{`Identifying canister ids. The output no longer hardcodes the canister ids into JavaScript, so you will need to provide that code using your own strategy. Other bundlers that allow for custom scripting should be able to re-use the webpack config logic.`}</li>
      <li parentName="ol">{`Determining `}<inlineCode parentName="li">{`NODE_ENV`}</inlineCode>{`. During development, the app should call `}<inlineCode parentName="li">{`agent.fetchRootKey()`}</inlineCode>{`, but it should not fetch the root key in production. See `}<a parentName="li" {...{
          "href": "#change-to-index-file"
        }}>{`Change to the index file`}</a>{`.`}</li>
      <li parentName="ol">{`Copying the codegen is optional. You still have access to the `}<inlineCode parentName="li">{`.did.js`}</inlineCode>{` and `}<inlineCode parentName="li">{`.did.d.ts`}</inlineCode>{` files in `}<inlineCode parentName="li">{`.dfx`}</inlineCode>{`, so you can choose to ignore the new `}<inlineCode parentName="li">{`index.js`}</inlineCode>{` format if it is inconvenient, and continue providing your own Actor.createActor pattern as before. `}</li>
      <li parentName="ol">{`Return types - if you do not want to use the dfx-provided files, consider using the JSDoc comments that we have come up with. If the code knows that your actor has a type of `}<inlineCode parentName="li">{`ActorSubclass<_SERVICE>`}</inlineCode>{`, for your particular service, the development process is significantly enhanced in compatible editors.`}</li>
    </ol>
    <h3 {...{
      "id": "minimal-update"
    }}><a parentName="h3" {...{
        "href": "#minimal-update"
      }}>{`Minimal Update`}</a></h3>
    <p>{`Finally, if you are looking to minimally modify your project, here is all you need to do, assuming you are starting from the 0.7.2 starter:`}</p>
    <p>{`You can continue using a query parameter in your URL, you can access it via `}</p>
    <pre {...{
      "className": "language-js"
    }}><code parentName="pre" {...{
        "className": "language-js"
      }}><span parentName="code" {...{
          "className": "token comment"
        }}>{`// src/example_assets/src/index.js`}</span>{`
`}<span parentName="code" {...{
          "className": "token keyword module"
        }}>{`import`}</span>{` `}<span parentName="code" {...{
          "className": "token imports"
        }}><span parentName="span" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{` idlFactory `}<span parentName="span" {...{
            "className": "token keyword module"
          }}>{`as`}</span>{` example_idl `}<span parentName="span" {...{
            "className": "token punctuation"
          }}>{`}`}</span></span>{` `}<span parentName="code" {...{
          "className": "token keyword module"
        }}>{`from`}</span>{` `}<span parentName="code" {...{
          "className": "token string"
        }}>{`'dfx-generated/example/example.did.js'`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`;`}</span>{`
`}<span parentName="code" {...{
          "className": "token keyword module"
        }}>{`import`}</span>{` `}<span parentName="code" {...{
          "className": "token imports"
        }}>{`canisterIds`}</span>{` `}<span parentName="code" {...{
          "className": "token keyword module"
        }}>{`from`}</span>{` `}<span parentName="code" {...{
          "className": "token string"
        }}>{`'../../../.dfx/local/canister_ids.json'`}</span>{`

`}<span parentName="code" {...{
          "className": "token keyword"
        }}>{`const`}</span>{` example_id `}<span parentName="code" {...{
          "className": "token operator"
        }}>{`=`}</span>{` `}<span parentName="code" {...{
          "className": "token keyword"
        }}>{`new`}</span>{` `}<span parentName="code" {...{
          "className": "token class-name"
        }}>{`URLSearchParams`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`(`}</span><span parentName="code" {...{
          "className": "token dom variable"
        }}>{`window`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`.`}</span><span parentName="code" {...{
          "className": "token property-access"
        }}>{`location`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`.`}</span><span parentName="code" {...{
          "className": "token property-access"
        }}>{`search`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`)`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`.`}</span><span parentName="code" {...{
          "className": "token method function property-access"
        }}>{`get`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`(`}</span><span parentName="code" {...{
          "className": "token string"
        }}>{`"exampleId"`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`)`}</span>{` `}<span parentName="code" {...{
          "className": "token operator"
        }}>{`||`}</span>{` canisterIds`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`.`}</span><span parentName="code" {...{
          "className": "token property-access"
        }}>{`example`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`.`}</span><span parentName="code" {...{
          "className": "token property-access"
        }}>{`local`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`;`}</span>{`

`}<span parentName="code" {...{
          "className": "token keyword"
        }}>{`const`}</span>{` agent `}<span parentName="code" {...{
          "className": "token operator"
        }}>{`=`}</span>{` `}<span parentName="code" {...{
          "className": "token keyword"
        }}>{`new`}</span>{` `}<span parentName="code" {...{
          "className": "token class-name"
        }}>{`HttpAgent`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`(`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`)`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`;`}</span>{`
agent`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`.`}</span><span parentName="code" {...{
          "className": "token method function property-access"
        }}>{`fetchRootKey`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`(`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`)`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`;`}</span>{`
`}<span parentName="code" {...{
          "className": "token keyword"
        }}>{`const`}</span>{` example `}<span parentName="code" {...{
          "className": "token operator"
        }}>{`=`}</span>{` `}<span parentName="code" {...{
          "className": "token maybe-class-name"
        }}>{`Actor`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`.`}</span><span parentName="code" {...{
          "className": "token method function property-access"
        }}>{`createActor`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`(`}</span>{`example_idl`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`,`}</span>{` `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`{`}</span>{` agent`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`,`}</span>{` canisterId`}<span parentName="code" {...{
          "className": "token operator"
        }}>{`:`}</span>{` example_id `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`}`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`)`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`;`}</span>{`
`}</code></pre>
    <p>{`And you can modify the `}<inlineCode parentName="p">{`aliases`}</inlineCode>{` reducer to point to the path, rather than hardcoding the old `}<inlineCode parentName="p">{`<canister-name>.js`}</inlineCode>{` file`}</p>
    <pre {...{
      "className": "language-js"
    }}><code parentName="pre" {...{
        "className": "language-js"
      }}><span parentName="code" {...{
          "className": "token comment"
        }}>{`// webpack.config.js`}</span>{`

`}<span parentName="code" {...{
          "className": "token comment"
        }}>{`// Old`}</span>{`
`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`[`}</span><span parentName="code" {...{
          "className": "token string"
        }}>{`"dfx-generated/"`}</span>{` `}<span parentName="code" {...{
          "className": "token operator"
        }}>{`+`}</span>{` name`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`]`}</span><span parentName="code" {...{
          "className": "token operator"
        }}>{`:`}</span>{` path`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`.`}</span><span parentName="code" {...{
          "className": "token method function property-access"
        }}>{`join`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`(`}</span>{`outputRoot`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`,`}</span>{` name `}<span parentName="code" {...{
          "className": "token operator"
        }}>{`+`}</span>{` `}<span parentName="code" {...{
          "className": "token string"
        }}>{`".js"`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`)`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`,`}</span>{`
`}<span parentName="code" {...{
          "className": "token comment"
        }}>{`// New`}</span>{`
`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`[`}</span><span parentName="code" {...{
          "className": "token string"
        }}>{`"dfx-generated/"`}</span>{` `}<span parentName="code" {...{
          "className": "token operator"
        }}>{`+`}</span>{` name`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`]`}</span><span parentName="code" {...{
          "className": "token operator"
        }}>{`:`}</span>{` path`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`.`}</span><span parentName="code" {...{
          "className": "token method function property-access"
        }}>{`join`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`(`}</span>{`outputRoot`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`)`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`,`}</span>{`
`}</code></pre>
    <p>{`Then, you can simply `}<inlineCode parentName="p">{`dfx deploy`}</inlineCode>{` like normal and visit your working site with `}<a parentName="p" {...{
        "href": "http://localhost:8000/?canisterId=ryjl3-tyaaa-aaaaa-aaaba-cai&exampleId=rrkah-fqaaa-aaaaa-aaaaq-cai"
      }}>{`http://localhost:8000/?canisterId=ryjl3-tyaaa-aaaaa-aaaba-cai&exampleId=rrkah-fqaaa-aaaaa-aaaaq-cai`}</a>{`.`}</p>
    <p>{`Hope this all helps, and feel free to reach out at `}<a parentName="p" {...{
        "href": "https://forum.dfinity.org/"
      }}>{`https://forum.dfinity.org/`}</a>{` or to email me at `}<a href="mailto:kyle.peacock@dfinity.org"><a parentName="p" {...{
          "href": "mailto:kyle.peacock@dfinity.org"
        }}>{`kyle.peacock@dfinity.org`}</a></a>{` if you have further questions.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      